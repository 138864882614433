import React from 'react';

export default function Cards(props) {
  return (
    <div
      className="relative bg-cover bg-center rounded-lg shadow-md overflow-hidden transition duration-300 ease-in-out transform scale-90 hover:scale-100 w-full h-64"
      style={{ backgroundImage: `url(${props.photo})` }}
    >
      <div className="absolute inset-0 bg-black opacity-40"></div>
      <div className="relative p-6 text-center">
        <h3 className="text-3xl text-white font-extrabold mb-2 caveat satisfy-regular">
          {props.name}
        </h3>
      </div>
      {(props.tag && props.tag === "false") ? null : (
        <div className="relative flex justify-center items-baseline my-8">
          <h3 className="m-5 text-xl text-white">Starting From</h3>
          <span className="mr-2 text-xl font-extrabold text-white">{props.price}</span>
        </div>
      )}
    </div>
  );
}
