import React from 'react';
import pondi from '../images/pondilog.avif'
import kashmir from '../images/kashmir.jpg'
import Cards from './cards';
import wing from '../images/wings.png';
import wingr from '../images/wingr.png';
export default function Section(props) {
  return (
    <section className={`${props.background} py-10`} id={`${props.name}`}>
      <div className="container mx-auto ">
        
        <div className='flex items-center justify-center'>
        <img src={wing} className='w-36'></img>
          <h2 className="text-3xl font-bold text-gray-800 mb-8  text-center">
          {props.name}
        </h2>
        <img src={wingr} className='w-36'></img></div>
        <div className={`grid grid-cols-1 md:grid-cols-3 `}>
           {props.photos && props.photos.map((photo, index) => (
            <Cards photo={photo.value} name={photo.name} desc={photo.desc} price={photo.price} tag={photo.tag} ></Cards>
          ))}
          
        </div>
      </div>
    </section>
  );
}
