import React, { useEffect, useState } from 'react'
import Navbar from './navbar'
import axios from 'axios';
import { Link } from 'react-router-dom';
import Slidingimg from './slidingimg';
import Section from './section';
import About from './about';
import Contact from './contact';
import Testimonies from './testimonies';
import logo from '../images/logo.webp'; 
import icon from '../images/search.png';
import photo1 from '../images/Photos/Bali/bali1';
import photo2 from '../images/Photos/bangkok/bang1.jpg';
import photo3 from '../images/Photos/bhutan/bhutan1';
import photo4 from '../images/Photos/canada/c1';
import photo5 from '../images/Photos/Dubai/dubai1';
import photo6 from '../images/Photos/goa/goa';
import photo7 from '../images/Photos/Kashmir/kashmir1.jpeg';
import photo8 from '../images/Photos/kerala/k2';
import photo9 from '../images/Photos/Mauritius/mauritius2';
import photo10 from '../images/Photos/morroco/m2';
import photo11 from '../images/Photos/pondicherry/p3';
import photo12 from '../images/Photos/russia/r2.jpg';
import photo13 from '../images/Photos/Sikkim/sikkim1.jpeg';
import photo14 from '../images/Photos/Singapore/singapore.jpg';
import photo15 from '../images/Photos/srilanka/sri4.jpg';
import cruise1 from '../images/cruise1.avif';
import cruise2 from '../images/cruise2.avif';
import vaish from '../images/vaishnodevi.avif';
import kedar from '../images/kedarnath.jpg';
import badri from '../images/badrinath.jpg';
import visa1 from '../images/Visa/visa photo (2) (1)/visa photo/visa1.jpg';
import visa2 from '../images/Visa/visa photo (2) (1)/visa photo/visa2.jpg';
import flight from '../images/flight.png';
import hotel from '../images/hotel.png';
import holiday from '../images/holiday.png';
import cruise from '../images/cruise.png';
import offer from '../images/offer.png';
import contact from '../images/contact.png';
import bali from '../images/bali.png'
import Mail from './mail';
import Video from './video';
const Mainbox = () => {
    
  return (
    <>
     <Navbar></Navbar>
     {/* <Slidingimg></Slidingimg> */}
     <Video></Video>
      <Section name="Holiday" background="bg-gray-100" photos={
        [{name:"Bali",value :photo1, desc:"This is Bali",price:"₹34,999"},{name:"Bangkok",value :photo2,desc:"This is Bangkok",price: "₹19,999"},{name:"Bhutan",value :photo3,desc:"This is Bhutan",price:"₹24,999"},{name:"Canada",value :photo4,desc:"This is Canada",price :"₹1,33,999"},{name:"Dubai",value :photo5,desc:"This is Dubai",price:"₹19,999"}
        ,{name:"Goa",value :photo6,desc:"This is Goa",price:"₹19,999"},
        {name:"Kashmir",value :photo7,desc:"This is Kashmir",price:"₹19,999"}
        ,{name:"Kerala",value :photo8,desc:"This is Kerala",price:"₹21,999"},
        {name:"Mauritius",value :photo9,desc:"This is Mauritius",price:"₹38,999"}
        ,{name:"Morroco",value :photo10,desc:"This is Morroco",price:"₹91,999"},
        {name:"Pondicherry",value :photo11,desc:"This is Pondicherry",price:"₹13,999"}
        ,{name:"Russia",value :photo12,desc:"This is Russia",price:"₹27,999"},
        {name:"Sikkim",value :photo13,desc:"This is Sikkim",price:"₹17,999"}
        ,
        {name:"Singapore",value :photo14,desc:"This is Singapore",price:"₹40,999"}
        ,{name:"SriLanka",value :photo15,desc:"This is SriLanka",price:"₹18,999"}
        ]} ></Section>
      <Section name="Cruise" photos={[
        {name:"Europe Cruise",value :cruise1, desc:"", tag:"false"},{name:"Cordelia",value :cruise2, desc:"", tag:"false"}
      ]}></Section>
      <Section name="Pilgrimage" background="bg-gray-100"
      photos={[
        {name:"Kedarnath",value :kedar, desc:"", tag:"false"},{name:"Badrinath",value :badri, desc:"", tag:"false"}
        ,{name:"Vaishnodevi",value :vaish, desc:"", tag:"false"}
      ]}></Section>
      <Section name="Visa"
      photos={[
        {name:"Tourist Visa",value :visa1, desc:"", tag:"false"}
      ]}></Section>
      <About></About>
      <Testimonies></Testimonies>
      <Contact></Contact>
      <Mail></Mail>
     </>
  )
}

export default Mainbox