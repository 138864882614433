import React from 'react'
import about from '../images/logofinal.png'
export default function About() {
  return (
    <div><section className="bg-gray-100" id="aboutus">
    <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
        <div className="max-w-lg">
          <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">
            About Us
          </h2>
          <p className="mt-4 text-gray-600 text-lg">
          Welcome to Wings In Air, our company goal is to provide you with a chance for you to recharge, relax and unwind yourself. Wings In Air started with a simple idea to provide people with the luxury of marvellous and spectacular places in reasonable prices. We strive to eventually share our love for travelling to our customers and make them fall in love with the mere act of flying with wings in air.
          </p>
        </div>
        <div className="mt-12 md:mt-0">
          <img
            src={about}
            alt="About Us Image"
            className="object-cover"
          />
        </div>
      </div>
    </div>
  </section>
  </div>
  )
}
