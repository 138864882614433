import React from 'react'

export default function Holidays(props) {
  return (
    <div className="absolute left-1/3 top-14 mt-2 w-48 bg-white border border-gray-200 shadow-lg rounded-lg p-4 z-50" onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
    <ul>
      <li className="py-2 hover:bg-gray-100"><a href="#holiday1">Holiday Package 1</a></li>
      <li className="py-2 hover:bg-gray-100"><a href="#holiday2">Holiday Package 2</a></li>
      <li className="py-2 hover:bg-gray-100"><a href="#holiday3">Holiday Package 3</a></li>
      <li className="py-2 hover:bg-gray-100"><a href="#holiday4">Holiday Package 4</a></li>
    </ul>
  </div>

  )
}
