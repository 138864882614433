import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import img from '../images/whatsapp.png'
export default function Mail() {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [email, setEmail] = useState('');
  
  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      user_email: email,
      message: 'This is a test message',
    };

    emailjs.send('service_1l9gytl', 'template_wl4n056', templateParams, '8zAoKqX3SsAoq5f4v')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Email sent successfully!');
      }, (err) => {
        console.error('FAILED...', err);
        alert('Failed to send email.');
      });

    setEmail('');
    setIsFormVisible(false);
  };
  useEffect(() => {
    
    const handleScroll = () => {
      const slidingImgHeight = 4200;
      if (window.scrollY > slidingImgHeight ) {
        setIsFormVisible(true);
      } else {
        setIsFormVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
   
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <>
      <button
        className={`fixed bottom-4 right-4 inline-flex items-center justify-center text-sm font-medium disabled:pointer-events-none disabled:opacity-50 border rounded-full w-16 h-16 ${isFormVisible ? 'bg-blue-400' : 'bg-blue-800'} m-0 cursor-pointer border-gray-200 bg-none p-0 normal-case leading-5 hover:text-gray-900`}
        type="button"
        aria-haspopup="dialog"
        aria-expanded={isFormVisible}
        data-state={isFormVisible ? 'open' : 'closed'}
        onMouseEnter={() => setIsFormVisible(true)}
        onMouseLeave={() => setIsFormVisible(false)}
        onClick={toggleFormVisibility}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="text-white block border-gray-200 align-middle"
        >
          <path d="m3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z" className="border-gray-200"></path>
        </svg>
      </button>

      {isFormVisible && (
        <div
          className="fixed bottom-20 right-0 p-4 py-6 rounded-lg bg-gray-50 dark:bg-gray-800 md:p-8"
          onMouseEnter={() => setIsFormVisible(true)}
        //   onMouseLeave={() => setIsFormVisible(false)}
        >
          <div>
                <a  href="https://wa.me/9949522903"><span class="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 dark:bg-gray-800">
                   <img src={img} className='w-6'></img>
                </span>

                <h2 class="mt-4 text-lg font-medium text-gray-800 dark:text-white">Whatsapp</h2>
                <p class="mt-2 text-gray-500 dark:text-gray-400">Chat With Us.</p></a>
            </div>
            <div></div>
          <form onSubmit={handleSubmit}>
            <p className="text-xs font-serif text-center text-white">Our Team will shortly reach back to you</p>
            <div className="mt-4">
              <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                Email address
              </label>
              <input
                type="email"
                placeholder="Enter Your Mail Id"
                value={email}
                onChange={handleEmailChange}
                className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>
            <button
              type="submit"
              className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-lg hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
            >
              Reach Us
            </button>
          </form>
        </div>
      )}
    </>
  );
}
