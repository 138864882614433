import './App.css';
import React from 'react';  
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import Login from './views/login';
import Mainbox from './views/mainbox';
import City from './views/city';

function App() {
  return (
    <BrowserRouter>
    <Routes>
    <Route exact path="/login" element ={<Login/>} />
    <Route exact path="/" element ={<Mainbox/>} />
    <Route exact path="/city" element ={<City/>} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
